import axios from 'axios';
// import { Message } from 'element-ui';
import store from '@/store/store';
import { getToken } from '@/utils/auth';
import constants from '@/utils/constants';

// create an axios instance
const service = axios.create({
	baseURL: constants.baseURL_DATABASES_API,
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 5000 // request timeout
});

service.defaults.timeout = 200000;

// request interceptor
service.interceptors.request.use(
	(config) => {
		// do something before request is sent

		if (store.getters.token) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			config.headers['X-Token'] = getToken();
		}
		return config;
	},
	(error) => {
		// do something with request error
		console.error('err', error); // for debug
		return Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	(response) => {
		const res = response.data;

		return res;
	},
	(error) => {
		console.error('err', error); // for debug
		/*
		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		});
		*/
		return Promise.reject(error);
	}
);

export default service;
