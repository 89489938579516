import { NOTIFICATIONS_LAYER } from './layers';

const views = {
	[NOTIFICATIONS_LAYER]: 'v_pm_notifications'
};

const preffixes = {
	[NOTIFICATIONS_LAYER]: 'pn'
};

export default {
	views: views,
	preffixes: preffixes
};
