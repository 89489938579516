import ViewsMixin from './ViewsMixin';
import { debounce } from 'lodash';
import Pui9HttpRequests from '@Pui9Requests';

const formatQuery = (str) => str.replace(/(?:\r\n\t|\r|\n|\t)/g, ' ').replace(/ {2,}/g, ' ');

export default {
	mixins: [ViewsMixin],

	computed: {
		bbox() {
			return this.$store.getters.bbox;
		}
	},

	data() {
		return {
			setQueryLoading: debounce(this._setQueryLoading.bind(null, true), 250),
			unsetQueryLoading: debounce(this._setQueryLoading.bind(null, false), 250)
		};
	},

	methods: {
		async runQuery(query) {
			const url = '/mapRequests/query';
			query = formatQuery(query);
			query = Buffer.from(query).toString('base64');

			this.setQueryLoading();
			const rows = await this.doQueryRequest(url, query);
			this.unsetQueryLoading();
			return rows;
		},

		async doQueryRequest(endpoint, data) {
			let rows = [];

			await Pui9HttpRequests.postRequest(
				endpoint,
				data,
				(response) => {
					if (response) {
						response.success = true;
						rows = response.data.rows;
					}
				},
				(error) => {
					console.log('-- Error obtained --');
					console.log(error);
				}
			);

			return rows;
		},

		_setQueryLoading(value) {
			const mutation = value ? 'addLoadingProcess' : 'removeLoadingProcess';
			this.$store.commit(mutation);
		},

		/**
		 * MISC
		 */
		getFeaturesByBbox(viewName, bbox, limit, offset /* , datasoucetypeid */) {
			bbox = this.normalizedBoundingBox(bbox);

			let query = null;

			const pm_organization_id = this.userProperties.organizationid;
			const lang = this.userProperties.language;
			//const language = this.userProperties.language;

			query = `
					SELECT
						pm_notifications_id,
						pm_rules_id,
						parameter_acronym,
						parameter_value,
						icon_acronym,
						icon_route,
						code as station_code,
						code as identifier,
						original_elastic_id,
						elastic_index,
						pm_datasourcetype_id,
						longitude,
						latitude,
						provider_name,
						datasourcetype_name,
						severity_acronym AS status,
						rule_acronym,
						rule_name,
						start_date,
						population,
						is_average,
						is_mobile as ismobile
					FROM ${viewName}
					WHERE pn.pm_organization_id = ${pm_organization_id}
					AND the_geom && ST_MakeEnvelope(${bbox})
					AND pn.lang::text = '${lang}'::text`;
			// AND pn.lang_station =  '${language}'
			//AND pm_datasourcetype_id = ${datasoucetypeid}
			// TODO: Check w/ PUI9 integration (bbox filtering)

			if (limit) {
				query += ` ORDER BY pn.pm_organization_id DESC LIMIT ${limit}`;
			}

			if (offset) {
				query += ` OFFSET ${offset}`;
			}

			return this.runQuery(query);
		},

		/**
		 * KPIS NOTIFICATIONS
		 */

		getKpisTitlesOrganization() {
			const pm_organization_id = this.userProperties.organizationid;
			const language = this.userProperties.language;

			const query = `
				SELECT
					pa.pm_area_id,
					pat.name
				FROM pm_area pa
				LEFT join pm_area_tra pat on pa.pm_area_id = pat.pm_area_id
				WHERE pat.lang = '${language}' AND pa.pm_organization_id = ${pm_organization_id}
				`;

			return this.runQuery(query);
		},

		async getCountByLayer(viewName) {
			const query = `
				SELECT
					COUNT(*)
				FROM ${viewName}
				WHERE the_geom && ST_MakeEnvelope(-180, -90, 180, 90)
				AND pn.pm_organization_id = ${this.userProperties.organizationid}
				AND pn.lang::text = '${this.userProperties.language}'::text`;
			// AND pn.lang_station = '${language}'

			return this.runQuery(query);
		},

		getNotificationKpisCount_byArea(bbox) {
			bbox = this.normalizedBoundingBox(bbox);

			const query = `
				SELECT
					pa.pm_area_id AS area_id,
					severity_acronym AS status,
					COUNT(1) AS count
				FROM ${this.notificationsView}
				LEFT JOIN pm_area pa ON pa.pm_area_id = pn.pm_area_id
				WHERE pn.pm_organization_id = ${this.userProperties.organizationid}
				AND the_geom && ST_MakeEnvelope(${bbox})
				AND pn.lang::text = '${this.userProperties.language}'::text
				GROUP BY pa.pm_area_id, pn.severity_acronym;
			`;
			// AND pn.lang_station = '${language}'

			return this.runQuery(query);
		},

		getNotificationKpisCount_byDatasourcetype(bbox) {
			bbox = this.normalizedBoundingBox(bbox);

			const query = `
				SELECT
					pn.pm_datasourcetype_id AS datasourcetype_id,
					severity_acronym AS status,
					COUNT(1) AS count
				FROM ${this.notificationsView}
				WHERE pn.pm_organization_id = ${this.userProperties.organizationid}
				AND the_geom && ST_MakeEnvelope(${bbox})
				AND pn.lang::text = '${this.userProperties.language}'::text
				GROUP BY pn.pm_datasourcetype_id, pn.severity_acronym;
			`;
			// 				AND pn.lang_station = '${language}'
			// LEFT JOIN pm_area pa ON pa.pm_area_id = pn.pm_area_id

			return this.runQuery(query);
		},

		/**
		 * UTIL
		 */

		normalizedBoundingBox(bbox) {
			const [xmin, ymin, xmax, ymax] = bbox;

			return [xmin < -180 ? -180 : xmin, ymin < -90 ? -90 : ymin, xmax > 180 ? 180 : xmax, ymax > 90 ? 90 : ymax];
		},

		getActiveNotifications(viewName, deviceId = null, organizationId = null) {
			let query = null;

			const pm_organization_id = organizationId ?? this.userProperties.organizationid;
			const lang = this.userProperties.language;
			//const language = this.userProperties.language;

			query = `
					SELECT
						pm_notifications_id,
						pm_rules_id,
						rule_acronym,
						start_date,
						datasourcetype_name,
						provider_name,
						url,
						contact_name,
						contact_email,
						icon_route,
						original_elastic_id,
						elastic_index,
						severity_color,
						is_average,
						parameter_value,
						parameter_acronym,
						population,
						filter_is_query,
						filter
					FROM ${viewName}
					WHERE pn.pm_organization_id = ${pm_organization_id}
					AND pn.lang::text = '${lang}'::text`;
			if (deviceId) {
				query += ` AND pn.pm_datasourceinstance_id = ${deviceId}`;
			}

			return this.runQuery(query);
		},
		getCompareAqiDate(viewName, areaId = null, organizationId = null, limit = 1, order = 'DESC') {
			let query = null;

			const pm_organization_id = organizationId ?? this.userProperties.organizationid;
			const lang = this.userProperties.language;

			query = `
					SELECT
						pm_aqi_values_id,
						date,
						date_timestamp
					FROM ${viewName}
					WHERE pav.pm_organization_id = ${pm_organization_id}
					AND pav.pm_area_id = ${areaId}
					AND pav.lang::text = '${lang}'::text
					ORDER BY pav.date ${order} LIMIT ${limit}`;

			return this.runQuery(query);
		}
	}
};
