import views from '@/lib/constants/views';
import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';

export default {
	computed: {
		views() {
			return views.views;
		},

		preffixes() {
			return views.preffixes;
		},

		notificationsView() {
			const preffix = views.preffixes[NOTIFICATIONS_LAYER];
			const view = views.views[NOTIFICATIONS_LAYER];
			return `"${view}" AS ${preffix}`;
		}
	}
};
