import request from '@/utils/request_databasesAPI';
import constants from '@/utils/constants';

export async function lastData(index, query) {
	return request({
		url: '/elastic/executeQuery/' + index,
		method: constants.METHOD_POST,
		data: query,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function getIndexFromElastic(index) {
	console.warn(index);
	return request({
		url: '/elastic/getIndices/' + index,
		method: constants.METHOD_GET,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}
export async function getPredictions(body) {
	console.log('body predictions');
	console.log(body);
	return request({
		url: '/predictions/customPrediction/',
		method: constants.METHOD_POST,
		data: body,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function createRule(model) {
	return request({
		url: '/rules/createRule/',
		method: constants.METHOD_POST,
		data: model,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function deleteRule(id) {
	return request({
		url: '/rules/deleteRule/',
		method: constants.METHOD_POST,
		data: id,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}
// pauseRule -> disable rule
// activateRule -> enable rule
// updateRule -> send formatted model to api

export async function disabledRule(model) {
	return request({
		url: '/rules/disableRule/',
		method: constants.METHOD_POST,
		data: model,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function enabledRule(model) {
	return request({
		url: '/rules/enableRule/',
		method: constants.METHOD_POST,
		data: model,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function updateRule(model) {
	return request({
		url: '/rules/updateRule/',
		method: constants.METHOD_POST,
		data: model,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function getElasticDocument(index, document) {
	console.log('debug elastic document', index, document);
	return request({
		url: '/elastic/getDocument/' + index + '/' + document,
		method: constants.METHOD_GET,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function validateQuery(index, query) {
	return request({
		url: '/elastic/validateQuery/' + index,
		method: constants.METHOD_POST,
		data: query,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function getAggs(index, query) {
	return request({
		url: '/elastic/getAggregations/' + index,
		method: constants.METHOD_POST,
		data: query,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}

export async function getErrorTypes(index, query) {
	return request({
		url: '/elastic/getErrorTypes/' + index,
		method: constants.METHOD_POST,
		data: query,
		headers: {
			'Content-Type': constants.Application_Json
		}
	});
}
